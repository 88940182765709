import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { finalize, takeUntil } from 'rxjs/operators'
import moment from 'moment'

import { DugnadsService } from '../../../../../core/api/dugnads.service'
import { BaseComponent } from '../../../../../shared/components/base/base.component'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { SnackbarService } from '../../../../../shared/components/snackbar/snackbar.service'
import { GroupSelectorState } from '../../../../../shared/modules/group-selector/states/group-selector.state'
import { DugnadTypes } from '../enums/dugnad-types'
import { ConfirmationStatus } from './enums/confirmation-status'
import { DugnadOrderPayload } from './interfaces/dugnad-order-payload'
import { SharedModule } from '../../../../../shared/modules/shared.module'
import { RepublishOrderComponent } from '../dugnads/republish-order/republish-order.component'
import { ValidationService } from '../../../../../core/services'
import { OrderStatus } from '../interfaces/order-status'
import { PaidStatus } from '../enums/paid-status'
import { Modal } from '../../../../../core/classes/global/modal'
import { DugnadOrder } from '../interfaces/dugnad-order'
import { SelectedGroup } from '../../../../../core/interfaces/organization/selected-group'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { DisableValueChangeOnWheelDirective } from '../../../../../shared/directives/disable-value-change-on-wheel.directive'

@Component({
  selector: 'app-edit-dugnad-order',
  templateUrl: './edit-dugnad-order.component.html',
  styleUrls: ['./edit-dugnad-order.component.scss'],
  host: { '[@editOrderAnimation]': '' },
  imports: [SharedModule, RepublishOrderComponent, DisableValueChangeOnWheelDirective],
  animations: [trigger('editOrderAnimation', OverlayAnimations.detailsPopup)]
})
export class EditDugnadOrderComponent extends BaseComponent implements OnInit {
  @Input() id: number

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup

  loading: boolean = false
  orderForm: FormGroup<{
    products: FormGroup<{
      [key: string]: FormControl<number | null>
    }>
    isConfirmed: FormControl<ConfirmationStatus | null>
    orderStatus: FormControl<OrderStatus | null>
    paymentPublishDate: FormControl<string | null>
    paymentDueDate: FormControl<string | null>
    paymentStatus: FormControl<PaidStatus | null>
    chargeDate?: FormControl<string | null>
    note: FormControl<string | null>
  }>
  dugnadOrder: DugnadOrder
  republishOrderModal: Modal = new Modal()
  today = moment().format(DateFormat.YYYYMMDD)

  get totalNumberOfItems(): number {
    let number = 0

    Object.keys(this.orderForm.controls.products.controls).forEach(
      (productId) => (number += this.orderForm.controls.products.value[productId]!)
    )

    return number
  }

  get DugnadTypes() {
    return DugnadTypes
  }

  get ConfirmationStatus() {
    return ConfirmationStatus
  }

  get OrderStatus() {
    return OrderStatus
  }

  get PaidStatus() {
    return PaidStatus
  }

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dugnadsService: DugnadsService,
    private snackbarService: SnackbarService
  ) {
    super()
  }

  ngOnInit() {
    this.getDugnadOrder()
  }

  private initForm(): void {
    this.orderForm = this.formBuilder.group({
      products: this.formBuilder.group(
        (() => {
          const fields = {}
          this.dugnadOrder.items.forEach((product) => {
            fields[product.product_id] = [product.items_number, [Validators.required, Validators.min(0)]]
          })

          return fields
        })()
      ),
      isConfirmed: [
        {
          value: this.dugnadOrder.is_confirmed ? ConfirmationStatus.Confirmed : ConfirmationStatus.NotConfirmed,
          disabled: this.dugnadOrder.paid_status === PaidStatus.Exempted
        },
        Validators.required
      ],
      orderStatus: [
        {
          value: this.dugnadOrder.order_status,
          disabled:
            (this.dugnadOrder.order_status === OrderStatus.BoughtOut &&
              this.dugnadOrder.paid_status === PaidStatus.Paid) ||
            this.dugnadOrder.paid_status === PaidStatus.Exempted
        },
        Validators.required
      ],
      paymentPublishDate: [
        {
          value: moment(this.dugnadOrder.publish_date).format(DateFormat.YYYYMMDD),
          disabled:
            moment(this.dugnadOrder!.publish_date).isBefore(moment()) ||
            this.dugnadOrder.paid_status !== PaidStatus.NotPaid
        },
        [
          Validators.required,
          ValidationService.minDate({
            minDate: moment(this.dugnadOrder!.dugnad.publish_date).format(DateFormat.YYYYMMDD)
          })
        ]
      ],
      paymentDueDate: [
        {
          value: this.dugnadOrder.payment_due_date
            ? moment(this.dugnadOrder.payment_due_date).format(DateFormat.YYYYMMDD)
            : '',
          disabled: this.dugnadOrder.paid_status === PaidStatus.Exempted
        },
        this.dugnadOrder.dugnad.dugnad_type === DugnadTypes.Payment ? Validators.required : null
      ],
      paymentStatus: [this.dugnadOrder.paid_status, Validators.required],
      note: [this.dugnadOrder.note]
    })

    this.orderForm.controls.isConfirmed.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      if (
        this.dugnadOrder.is_confirmed &&
        value === ConfirmationStatus.NotConfirmed &&
        this.orderForm.value.paymentStatus === PaidStatus.NotPaid &&
        moment(this.dugnadOrder.confirmation_due_date).isAfter()
      ) {
        this.republishOrderModal.open()
      }
    })

    this.orderForm.controls.paymentStatus.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      Object.keys(this.orderForm.controls.products.controls).forEach((productId) => {
        if (value === PaidStatus.Exempted) {
          this.orderForm.controls.products.controls[productId].disable({ emitEvent: false })
        } else {
          this.orderForm.controls.products.controls[productId].enable({ emitEvent: false })
        }
      })

      if (value === PaidStatus.Exempted) {
        this.orderForm.controls.isConfirmed.disable({ emitEvent: false })
        this.orderForm.controls.orderStatus.disable({ emitEvent: false })
        this.orderForm.controls.paymentDueDate.disable({ emitEvent: false })
      } else {
        this.orderForm.controls.isConfirmed.enable({ emitEvent: false })
        this.orderForm.controls.orderStatus.enable({ emitEvent: false })
        this.orderForm.controls.paymentDueDate.enable({ emitEvent: false })
      }

      if (value === PaidStatus.PaidManually) {
        this.orderForm.addControl(
          'chargeDate',
          new FormControl(this.today, [Validators.required, ValidationService.maxDate({ maxDate: this.today })])
        )
      } else {
        this.orderForm.removeControl('chargeDate')
      }

      if (value === PaidStatus.NotPaid && moment(this.dugnadOrder!.publish_date).isAfter(moment())) {
        this.orderForm.controls.paymentPublishDate.enable({ emitEvent: false })
      } else {
        this.orderForm.controls.paymentPublishDate.disable({ emitEvent: false })
      }
    })
  }

  private getDugnadOrder(): void {
    this.loading = true
    this.dugnadsService
      .getDugnadOrder(this.id, this.selectedGroup.id)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => {
        this.dugnadOrder = response
        this.initForm()
      })
  }

  closeRepublishOrderModal(shouldRepublish: boolean): void {
    if (!shouldRepublish) {
      this.orderForm.patchValue({ isConfirmed: ConfirmationStatus.Confirmed })
    }

    this.republishOrderModal.close()
  }

  saveOrder(): void {
    if (this.orderForm.valid) {
      const payload: DugnadOrderPayload = {
        items: [],
        order_status: this.orderForm.controls.orderStatus.value!,
        payment_status: this.orderForm.value.paymentStatus!,
        payment_due_date: this.orderForm.controls.paymentDueDate.value || null,
        note: this.orderForm.value.note!
      }

      if (this.orderForm.value.paymentPublishDate) {
        payload.publish_date = this.orderForm.value.paymentPublishDate
      }

      Object.keys(this.orderForm.controls.products.value).forEach((productId) => {
        payload.items!.push({
          product_id: Number(productId),
          items_number: this.orderForm.controls.products.value[productId]!
        })
      })

      if (this.dugnadOrder.dugnad.dugnad_type === DugnadTypes.Order) {
        payload.is_confirmed = this.orderForm.controls.isConfirmed.value === ConfirmationStatus.Confirmed
      }

      if (this.orderForm.value.paymentStatus === PaidStatus.PaidManually) {
        payload.charge_made = this.orderForm.value.chargeDate!
      }

      this.loading = true
      this.dugnadsService
        .updateOrder(this.dugnadOrder.id, this.selectedGroup.id, payload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this.snackbarService.success(
            this.translate.instant('economy.dugnad_update_success', { name: this.dugnadOrder.user_name })
          )
          this.close.emit(true)
        })
    }
  }

  onClose(): void {
    this.close.emit(false)
  }
}
