<app-overlay [loading]="loading" appFloatingElement (close)="onClose()">
  <div class="overlay__buttons">
    <app-button theme="transparent" class="back-button" (click)="onClose()">
      {{ 'action.back' | translate }}
    </app-button>
    <app-button class="next-button" [disabled]="!!orderForm?.invalid" (click)="saveOrder()">
      {{ 'action.save' | translate }}
    </app-button>
  </div>

  <app-fade-in *ngIf="dugnadOrder">
    <div class="overlay__header">
      <h2>{{ 'economy.edit_dugnad_for' | translate }} {{ dugnadOrder.user_name }}</h2>
      <p class="sub-header ns-text small">{{ dugnadOrder.dugnad.title }}</p>
    </div>

    <div class="overlay__body overlay__body--default-width">
      <div
        *ngIf="dugnadOrder.dugnad.dugnad_type === DugnadTypes.Order && !dugnadOrder.fixed_items_number"
        class="ns-order-description"
      >
        <p class="ns-text">
          {{
            'orders.edit_dugnad_order_description_1_1'
              | translate : { name: dugnadOrder.user_name, number: totalNumberOfItems }
          }}
        </p>
        <p class="ns-text">{{ 'orders.edit_dugnad_order_description_1_2' | translate }}</p>
      </div>

      <div
        *ngIf="dugnadOrder.dugnad.dugnad_type === DugnadTypes.Order && dugnadOrder.fixed_items_number"
        class="ns-order-description"
      >
        <p class="ns-text">
          {{
            'orders.edit_dugnad_order_description_2_1'
              | translate : { name: dugnadOrder.user_name, number: totalNumberOfItems }
          }}
        </p>
        <p class="ns-text">{{ 'orders.edit_dugnad_order_description_2_2' | translate }}</p>
      </div>

      <div
        *ngIf="dugnadOrder.dugnad.dugnad_type === DugnadTypes.Payment && !dugnadOrder.fixed_items_number"
        class="ns-order-description"
      >
        <p class="ns-text">
          {{
            'orders.edit_dugnad_order_description_3_1'
              | translate : { name: dugnadOrder.user_name, number: totalNumberOfItems }
          }}
        </p>
        <p class="ns-text">{{ 'orders.edit_dugnad_order_description_3_2' | translate }}</p>
      </div>

      <div
        *ngIf="dugnadOrder.dugnad.dugnad_type === DugnadTypes.Payment && dugnadOrder.fixed_items_number"
        class="ns-order-description"
      >
        <p class="ns-text">
          {{
            'orders.edit_dugnad_order_description_4_1'
              | translate : { name: dugnadOrder.user_name, number: totalNumberOfItems }
          }}
        </p>
        <p class="ns-text">{{ 'orders.edit_dugnad_order_description_4_2' | translate }}</p>
      </div>

      <form [formGroup]="orderForm" class="ns-form ns-light">
        <ng-container *ngFor="let product of dugnadOrder.items" formGroupName="products">
          <p class="ns-product-subtitle ns-subtitle small">
            {{ 'orders.number_of_items_for' | translate }} {{ product.name }}
          </p>
          <mat-form-field>
            <mat-label>{{ 'orders.items' | translate }}</mat-label>
            <input
              matInput
              type="number"
              [formControlName]="product.product_id"
              [min]="0"
              autocomplete="off"
              appDisableValueChangeOnWheel
            />
          </mat-form-field>
        </ng-container>

        <mat-form-field *ngIf="dugnadOrder.dugnad.dugnad_type === DugnadTypes.Order">
          <mat-label>{{ 'table.confirmation_status' | translate }}</mat-label>
          <mat-select formControlName="isConfirmed">
            <mat-option [value]="ConfirmationStatus.NotConfirmed">{{ 'orders.not_confirmed' | translate }}</mat-option>
            <mat-option [value]="ConfirmationStatus.Confirmed">{{ 'orders.confirmed' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'table.order_status' | translate }}</mat-label>
          <mat-select formControlName="orderStatus">
            <mat-option [value]="OrderStatus.NotSet">
              {{ 'economy.not_picked_up' | translate }}
            </mat-option>
            <mat-option [value]="OrderStatus.PickedUp">
              {{ 'economy.picked_up' | translate }}
            </mat-option>
            <mat-option
              *ngIf="
                (dugnadOrder.dugnad.has_buyout_option &&
                  dugnadOrder.paid_status !== PaidStatus.Paid &&
                  dugnadOrder.paid_status !== PaidStatus.PaidManually) ||
                dugnadOrder.order_status === OrderStatus.BoughtOut
              "
              [value]="OrderStatus.BoughtOut"
            >
              {{ 'economy.bought_out' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'payment.publish_date' | translate }}</mat-label>
          <input matInput type="date" formControlName="paymentPublishDate" />
          <mat-icon matPrefix>calendar_today</mat-icon>
          <mat-hint>{{ 'payment_details.publish_date_hint' | translate }}</mat-hint>

          @if (orderForm.controls.paymentPublishDate!.hasError('minDate')) {
            <mat-error>{{ 'payment_details.publish_date_error' | translate }}</mat-error>
          }
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'payment.payment_due_date' | translate }}</mat-label>
          <input matInput type="date" formControlName="paymentDueDate" />
          <mat-icon matPrefix>calendar_today</mat-icon>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'table.payment_status' | translate }}</mat-label>
          <mat-select formControlName="paymentStatus">
            <mat-option [value]="PaidStatus.NotPaid">{{ 'payment.not_paid' | translate }}</mat-option>
            <mat-option [value]="PaidStatus.Exempted">{{ 'payment.exempted' | translate }}</mat-option>
            <mat-option [value]="PaidStatus.PaidManually">{{ 'payment.paid_manually' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <app-expand-y
          *ngIf="orderForm.value.paymentStatus === PaidStatus.PaidManually"
          class="ns-expandable-field-wrapper"
        >
          <mat-form-field class="ns-expandable-field ns-no-gap">
            <mat-label>{{ 'economy.payment_date' | translate }}</mat-label>
            <input matInput type="date" formControlName="chargeDate" [max]="$any(today)" />
            <mat-icon matPrefix>calendar_today</mat-icon>
          </mat-form-field>
        </app-expand-y>

        <mat-form-field>
          <mat-label>{{ 'economy.payment_note' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="note"
            [rows]="2"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
          ></textarea>
        </mat-form-field>
      </form>
    </div>
  </app-fade-in>
</app-overlay>

<app-republish-order
  *ngIf="republishOrderModal.isOpen"
  [name]="dugnadOrder.user_name"
  [confirmationDueDate]="dugnadOrder.confirmation_due_date"
  (close)="closeRepublishOrderModal($event)"
></app-republish-order>
